#root, .App {
  position: relative;
  min-height: 100vh;
  overflow: auto;
}

.container {
  max-width: 1300px;
  margin-bottom: 100px;
  padding: 0;
  padding-top: 20px;
}

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

footer#footer {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  height: 2.5rem;
  font-size: 14px;
  color: #736f6f;
}

/* links */
a, a:focus, a:visited, a:active {
  text-decoration: none;
  word-break: break-word;
}

a {
  color: inherit;
}

/* ViewReport - ReportText */
@media (min-width: 1010px) {
  p {
    line-height: 1.2;
  }
}

@media (min-width: 1300px) {
  p {
    line-height: 1.4;
  }
}

@media (min-width: 1600px) {
  p {
    line-height: 1.5;
  }
}

.fieldset {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.legend {
  background-color: #fff;
}

.required {
  color: #f00;
  font-size: 12px;
}

.overflow {
  overflow: visible;
}

.wait {
  cursor: wait;
}

.wait * {
  pointer-events: none;
}

.smallSelect {
  width: 50%;
}

.card {
  padding: 20px;
}
