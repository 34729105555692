.Presentation table tr, .Presentation table td, .Presentation table th {
	padding: 5px;
}

.error-boundary {
  color: red;
  text-align: center;
  padding: 10px;
}

.error-boundary h1 {
  font-weight: normal;
  color: red;
}

.error-boundary p {
  text-transform: uppercase;
}

.Text p[id*="90"] {
  transform: rotate(90deg);
}

.Text p[id*="270"] {
  transform: rotate(270deg);
}

.Text a, .Text a:focus, .Text a:visited, .Text a:active {
  color: #000;
  text-decoration: underline;
}

.recharts-legend-wrapper {
  padding: 10px;
}

.stacked_column .recharts-default-legend {
  display: flex;
  flex-direction: column-reverse;
}

#titleTooltip .MuiTooltip-tooltip {
  max-width: 500px;
}
